import React from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

const ElevationScroll = ({ children, window }) => {
  const triggered = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  const props = {
    elevation: triggered ? 4 : 0,
    triggered
  };

  if (typeof children === 'function') {
    return children(props);
  }

  return React.cloneElement(children, props);
};

ElevationScroll.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element
  ]).isRequired,
  window: PropTypes.func
};

export default ElevationScroll;
